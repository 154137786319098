import Footer from "components/footers/ContactFooter";
import {SectionHeading} from "components/misc/Headings.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import React from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import tw from "twin.macro";
import Header from "../components/headers/light";
import {Link} from "react-router-dom";
import ContactForm from "../components/forms/ContactForm";
import {ReactComponent as PhoneIcon} from "../images/phone-icon.svg";
import {navLinks} from "components/misc/NavLinks";
import VirtualTour from "../components/misc/VirtualTour";

const Container = tw.div`relative sm:mx-16 md:mx-40 pb-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between py-1`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TitleColumn = tw(Column)`md:w-8/12 lg:w-9/12 md:h-auto`;
const PhoneColumn = tw(Column)`md:w-4/12 lg:w-3/12 md:mt-0 sm:mr-4`;

const Heading = tw(
    SectionHeading
)`text-left text-xl sm:text-3xl lg:text-4xl text-center md:text-left text-gray-900`;

const SubHeading = tw.h5`font-bold lg:text-2xl md:text-xl text-center  md:text-left  mb-3 text-primary-900`;
const Price = tw.h2`font-bold lg:text-4xl md:text-3xl text-center  md:text-left  mb-1 text-primary-500`;
const PricingMonthly = tw.span`text-lg font-bold text-center text-primary-500`;
const MonthlyDescription = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-900`;

const Description = tw.p`text-justify md:text-justify text-base md:text-base lg:text-lg font-medium leading-relaxed text-gray-900 mt-8`;
const ContactDescription = tw.p`text-right md:text-right text-base md:text-base lg:text-2xl font-medium pl-2 leading-relaxed text-gray-900`;
const IconWithText = tw.div`flex rounded-full items-center py-8 `;
const IconContainer = styled.div`
    ${tw`inline-block rounded-full p-2 bg-primary-800 text-white border-gray-900`}
    svg {
        ${tw`w-6 h-6`}
    }
`;

export default ({
                    card = {
                        title: "Útulný a praktický byt 2+kk v klidném prostředí",
                        tourLink: "https://my.matterport.com/show/?m=fWVg4YLbRBy",
                        images: [
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1730224525/kralupy/1_kopie_xthodz.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1730224525/kralupy/1_kopie_xthodz.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1730224525/kralupy/2_kopie_ustyah.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1730224525/kralupy/2_kopie_ustyah.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1730224525/kralupy/3_kopie_bb0q0e.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1730224525/kralupy/3_kopie_bb0q0e.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1730224525/kralupy/4_kopie_gk132x.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1730224525/kralupy/4_kopie_gk132x.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1730224525/kralupy/5_z6mf95.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1730224525/kralupy/5_z6mf95.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1730224525/kralupy/6_kopie_q2ib3u.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1730224525/kralupy/6_kopie_q2ib3u.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1730224525/kralupy/7_kopie_ohnbf2.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1730224525/kralupy/7_kopie_ohnbf2.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1730224525/kralupy/8_kopie_mncfqp.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1730224525/kralupy/8_kopie_mncfqp.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1730224525/kralupy/9_kopie_qieflm.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1730224525/kralupy/9_kopie_qieflm.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1730224525/kralupy/10_kopie_y5yhsy.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1730224525/kralupy/10_kopie_y5yhsy.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1730224525/kralupy/11_kopie_h2w5ca.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1730224525/kralupy/11_kopie_h2w5ca.jpg",
                            }
                        ],
                    },
                }) => {
    return (
        <>
            <div
                style={{
                    paddingTop: 15,
                    paddingBottom: 10,
                    paddingLeft: 30,
                    position: "absolute",
                    zIndex: 10,
                    width: "100%",
                    height: "10rem",
                    background:
                        "linear-gradient(180deg, rgba(26,32,44,1) 0%, rgba(26,32,44,0) 100%)",
                    color: "white",
                }}
            >
                <Header links={navLinks}/>
            </div>

            <ImageGallery
                showBullets
                showPlayButton={false}
                showThumbnails={false}
                items={card.images}
            />
            <AnimationRevealPage>
                <Container>
                    <TwoColumn>
                        <TitleColumn>
                            <Heading>{card.title}</Heading>
                            <SubHeading>
                                Nabízíme k prodeji útulný a praktický byt 2+kk o výměře 40 m<sup>2</sup>
                            </SubHeading>
                            <Price>3 850 000 Kč</Price>
                            <MonthlyDescription>
                                splátka hypotéky <PricingMonthly>19.500 Kč</PricingMonthly> /
                                měsíčně
                            </MonthlyDescription>
                        </TitleColumn>
                        <PhoneColumn textOnLeft={false}>
                            <IconWithText>
                                <IconContainer>
                                    <PhoneIcon/>
                                </IconContainer>
                                <ContactDescription>
                                    <Link href="tel:776 118 302">776 118 302</Link>
                                </ContactDescription>
                            </IconWithText>
                        </PhoneColumn>
                    </TwoColumn>
                    <Description>
                        Vítejte v Kralupech nad Vltavou, kde se nachází tento útulný a praktický byt 2+kk , který je
                        místem pro Váš nový domov nebo jako skvělá investiční příležitost.
                    </Description>
                    <Description>
                        Byt se nachází ve 3. nadzemním podlaží zatepleného bytového domu s výtahem. Díky své orientaci
                        na severozápad je byt světlý a vzdušný. Plastová okna opatřená žaluziemi zajišťují dostatek
                        denního světla, což je skvělá zpráva pro všechny, kdo milují přirozené osvětlení. Charakterem
                        bytu je jeho jednoduchá dispozice, která umožňuje přizpůsobení tak, aby vytvořil pohodlí a
                        příjemnou atmosféru domova.
                    </Description>
                    <Description>
                        Kuchyň je kompletně vybavena kuchyňskou linkou, spotřebiči (moderní plynová varná deska, trouba,
                        myčka, lednice, digestoř). V prostoru dětského pokoje je pod stropem vestavěná skříň s dostatkem
                        prostoru. Podlahy v bytě jsou převážně pokryty plovoucí podlahou a parkety v obývacím pokoji
                        které jsou praktické a snadno udržovatelné, zatímco koupelna a WC mají elegantní dlažbu.
                    </Description>
                    <Description>
                        K bytové jednotce náleží sklep (cca 2 m2), parkování je zabezpečeno na ulici před domem.
                    </Description>
                    <Description>
                        <SubHeading>EXTERIÉR A OKOLÍ:</SubHeading>
                        Dům je obklopen zelení, což vytváří příjemné prostředí pro relaxaci a odpočinek. Milovníci
                        aktivního životního stylu ocení blízkou cyklostezku u Vltavy, která nabízí možnosti pro pěší i
                        cyklo výlety směrem na Prahu nebo Roudnici. V okolí se také nachází krásná Dvořákova stezka do
                        Nelahozevsi a zámek Veltrusy s rozlehlým parkem.
                    </Description>
                    <Description>
                        <SubHeading>DOPRAVNÍ DOSTUPNOST A OBČANSKÁ VYBAVENOST:</SubHeading>
                        Výborná dopravní dostupnost je jedním z hlavních benefitů tohoto bytu. Na dálnici D8 se
                        dostanete autem za necelé 4 km a do Prahy je to přibližně 20 km. Město Kralupy nad Vltavou
                        nabízí bohatou občanskou vybavenost, nejbližší obchod je přímo před barákem a nejbližší obchodní
                        centra (Tesco, Lidl, Penny) jsou vzdálené 1km daleko. Ve vzdálenosti 200m je k dispozici
                        poliklinika s obvodními lékaři a lékárnou, školky i základní škola. Pro ty, kdo využívají
                        veřejnou dopravu, jsou zde frekventované autobusové spoje na Prahu i Mělník a vlakové nádraží
                        vzdálené cca 3 km nabízí časté spoje do Prahy či Ústí nad Labem.
                    </Description>
                    <Description>
                        Tento byt je skvělou volbou pro jednotlivce, páry i malé rodiny, kteří hledají klidné bydlení s
                        výbornou dostupností do Prahy a okolí nebo taky jako skvělá investiční příležitost.
                    </Description>
                    <Description>
                        Pro více informací nebo domluvení prohlídky nás neváhejte kontaktovat. Těšíme se na vaši
                        návštěvu a věříme, že se zde budete cítit jako doma.
                    </Description>
                </Container>
                <VirtualTour link={card.tourLink}/>
                <ContactForm subject={card.title}/>
                <Footer/>
            </AnimationRevealPage>
        </>
    );
};
