import React, {useRef, useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {SectionHeading, Subheading as SubheadingBase,} from "components/misc/Headings.js";
import {PrimaryButton as PrimaryButtonBase} from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import {ReactComponent as MailIcon} from "images/email-newsletter-icon.svg";
import {ReactComponent as PhoneIcon} from "images/phone-icon.svg";

const Container = tw.div`relative mx-16`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-8 md:py-16`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;
const ContactDetails = tw.div`font-semibold text-xl lg:text-2xl xl:text-3xl text-gray-800 text-center md:text-left`;


const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://www.neverejne.cz";

const CAPTCHA_KEY = "6LfsptAZAAAAAKev6HIwh287q4ybEbY5JtBSQZFR";

// const CAPTCHA_KEY =
//   process.env.NODE_ENV === "development"
//     ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
//     : "6LfkeNAZAAAAABGhUeZ9BNY_HOs_zm1zJXp0A1UH";e

export default ({
  subheading = (
    <>
      V případě zájmu neváhejte kontaktovat{" "}
      <span tw="text-primary-500">někoho z našeho týmu,</span>
      <wbr />
        který je Vám stále k dispozici:
    </>
  ),
  heading = "Kontaktujte nás",
  submitButtonText = "Odeslat",
  textOnLeft = true,
  subject,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [passedCaptcha, setPassedCaptcha] = useState(false);
  let captchaRef = useRef();

  const onCaptchaClick = () => {
    setPassedCaptcha(true);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!passedCaptcha) {
      alert("Nejdřív je potřebné potvrdit, že “nejsi robot”");
    } else {
      const data = new FormData(e.target);
      console.log(e.target);
      axios
        .post(`${BASE_URL}/api/user/contact-komercky`, {
          email: data.get("email"),
          name: data.get("name"),
          message: data.get("message"),
          phoneNumber: data.get("phoneNumber"),
          subject: subject,
        })
        .then(() => {
          document.getElementById("emailForm").reset();
          captchaRef.current.reset();
          setPassedCaptcha(false);
        })
        .catch((err) => {
          document.getElementById("emailForm").reset();
          captchaRef.current.reset();
          setPassedCaptcha(false);
        });
    }
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            {subheading && <Subheading>{subheading}</Subheading>}
              {/*<MailIcon tw="w-4 h-4 text-gray-600"/>*/}
              <ContactDetails>
                  <Link href="mailto:info@neverejne.cz">info@neverejne.cz</Link>
              </ContactDetails>
              <ContactDetails>
                  <Link href="tel:+420 601 101 463">+420 601 101 463</Link>
              </ContactDetails>
              {/*<Form onSubmit={sendEmail} id="emailForm">*/}
              {/*  <Input type="email" name="email" placeholder="Email" />*/}
              {/*  /!*<Input type="text" name="name" placeholder="Celé jméno"/>*!/*/}
              {/*  <Input type="tel" name="phoneNumber" placeholder="Telefón" />*/}
              {/*  /!*<Textarea name="message" placeholder="Vaše zpráva"/>*!/*/}
              {/*  <ReCAPTCHA*/}
              {/*    ref={captchaRef}*/}
              {/*    sitekey={CAPTCHA_KEY}*/}
              {/*    onChange={onCaptchaClick}*/}
              {/*    style={{ marginTop: 30 }}*/}
              {/*  />*/}
              {/*  <SubmitButton type="submit"> <Link href="mailto:info@neverejne.cz">Mail:*/}
              {/*    info@neverejne.cz</Link></SubmitButton>*/}
              {/*</Form>*/}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
